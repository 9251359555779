import React, { useEffect } from 'react';
import * as d3 from 'd3';

function PieChart(props) {
    const {
        data,
        outerRadius,
        innerRadius,
    } = props;

    const margin = {
        top: -20,
        right: 10,
        bottom: 20,
        left: 0,
    };

    const width = 2 * outerRadius + margin.left + margin.right;
    const height = 2 * outerRadius + margin.top + margin.bottom;



    const colorScale = d3.scaleQuantize()
        .domain([0, data.length])
        .range(["#1d0608", "#3a0c11", "#571319", "#741d22", "#91262b"]);

    useEffect(() => {
        drawChart();
    }, [data]);

    function drawChart() {
        // Remove the old svg
        d3.select('#pie-container')
            .select('svg')
            .remove();

        // Create new svg
        const svg = d3
            .select('#pie-container')
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const arcGenerator = d3
            .arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        const pieGenerator = d3
            .pie()
            .padAngle(0)
            .value((d) => d.value).sort(null);

        const arc = svg
            .selectAll()
            .data(pieGenerator(data))
            .enter();

        // Append arcs
        arc
            .append('path')
            .attr('d', arcGenerator)
            .style('fill', (_, i) => colorScale(i))
            .style('stroke', '#ffffff')
            .style('stroke-width', 0);

        // Append text labels
        arc
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text((d) => d.data.label)
            .style('fill', '#ffffff')
            .style('font-size', '9px')
            .attr('transform', (d) => {
                const [x, y] = arcGenerator.centroid(d);

                return `translate(${x*1.7}, ${y*1.7})`;
            });


    }

    return <div id = "pie-container" / > ;
}

export default PieChart;