import {
  AccountMeta,
  Connection,
  MemcmpFilter,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
  AccountLayout
} from "@solana/spl-token";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { serialize} from 'borsh';
import * as bs58 from "bs58";
/* global BigInt */




// export const PROGRAM_ID = new PublicKey(
//   "GeV8oDGvxeARycmsXS2rbJdv2gDfpFP9qaAkB2US96jK"
  
// );

export const PROGRAM_ID = new PublicKey(
  "GaaZ2w4fiitA477oRXs6fchhMJgMQ7jSQNRxACK3ugHE"  
);

const REWARD_MINT = new PublicKey(
  "GkywroLpkvYQc5dmFfd2RchVYycXZdaA5Uzix42iJdNo"
);

const ADMIN = new PublicKey(
  "8Hk1jJzPrYF566hZoCP4fvaVhjdxuNZ3Qo7ytMxyBq3H"
);


const PENALTY = new PublicKey(
  "CTABB6zm2Ktn1quLVn686McBREx716jejb3w5vBtyEqD"
);

    // const milestone_1 = 60*24*60*60;
    // const milestone_2 = 150*24*60*60;
    // const milestone_3 = 330*24*60*60;

    const milestone_1 =  60;
    const milestone_2 = 120;
    const milestone_3 = 200;
    

    const reward_multiplier_1 = 15;
    const reward_multiplier_2 = 20;
    const reward_multiplier_3 = 25;

let REWARD_MINT_DECIMALS = -1;
async function getRewardMintDecimals(connection: Connection): Promise<number> {
  if (REWARD_MINT_DECIMALS === -1) {
    let rewardMintInfo = await connection.getParsedAccountInfo(REWARD_MINT);
    let data = rewardMintInfo?.value?.data;
    if (data && !(data instanceof Buffer))
      REWARD_MINT_DECIMALS = data.parsed.info.decimals;
    else REWARD_MINT_DECIMALS = 9;
  }
  return REWARD_MINT_DECIMALS;
}
class Assignable {
  constructor(properties) {
    Object.keys(properties).map((key) => {
      return (this[key] = properties[key]);
    });
  }
}
class Payload extends Assignable {}

function createInstructionData(instruction: string, time_index): Buffer {
  const StakePayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["time_index", "u64"],
        ],
      },
    ],
  ]);
  if (instruction === "Stake") {
    const Data = new Payload({
      id:1,
      time_index
      });
    return Buffer.from(serialize(StakePayloadSchema, Data));
  }
  else if (instruction === "Unstake") return Buffer.from([2]);
  else if (instruction === "StakeWithdraw") return Buffer.from([5]);
  else if (instruction === "StakeUpgrade") return Buffer.from([6]);
  else if (instruction === "AdminUnstake") return Buffer.from([7]);

  throw new Error(`Unrecognized instruction: ${instruction}`);
}

function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}

function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}

function transactionKey(
  pubkey: PublicKey,
  isSigner: boolean,
  isWritable: boolean = true
): AccountMeta {
  return {
    pubkey,
    isSigner,
    isWritable,
  };
}

const PREFIX = "vault";
export async function getaddress(): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(PREFIX)],
    PROGRAM_ID
  );
  return address;
}

export async function getStakeDataAddress(
  token: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [token.toBytes()],
    PROGRAM_ID
  );
  return address;
}

export async function checkCandyMachineStakable(
  connection: Connection,
  candyMachine: string,
  forceRefresh: boolean = false
): Promise<boolean> {
  return (
    (await getCandyMachineRate(connection, candyMachine, forceRefresh)) != null
  );
}

const CANDY_MACHINE_RATE_CACHE = new Map<string, bigint | null>();
export async function getCandyMachineRate(
  connection: Connection,
  candyMachine: string,
  forceRefresh: boolean = false
): Promise<bigint | null> {
  if (forceRefresh || !CANDY_MACHINE_RATE_CACHE.has(candyMachine)) {
    let whitelistDataAddress = await getWhitelistDataAddress(
      new PublicKey(candyMachine)
    );

    let price = null;
    let whitelistData = await connection.getAccountInfo(whitelistDataAddress);
    if (whitelistData != null) price = parseUint64Le(whitelistData.data);

    CANDY_MACHINE_RATE_CACHE.set(candyMachine, price);
  }

  return CANDY_MACHINE_RATE_CACHE.get(candyMachine)!;
}

const WHITELIST_PREFIX = "whitelist";
export async function getWhitelistDataAddress(
  candyMachine: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(WHITELIST_PREFIX), candyMachine.toBytes()],
    PROGRAM_ID
  );
  return address;
}

export interface RewardCalculator {
  minPeriod: bigint;
  rewardPeriod: bigint;
  staked_1: bigint,
  staked_2: bigint,
  staked_3: bigint,
  staked_4: bigint,
  staked_5: bigint,
}

export interface NFT {
  // minPeriod: bigint;
  // rewardPeriod: bigint;
  name: string;
}

export async function getRewardCalculator(
  connection: Connection
): Promise<RewardCalculator> {
  let address = await getaddress();
  let valutAccountInfo = await connection.getAccountInfo(address);
  if (!valutAccountInfo) throw new Error(`${address} not initialized`);
  let { data } = valutAccountInfo;
  return {
    minPeriod: parseUint64Le(data, 0),
    rewardPeriod: parseUint64Le(data, 8),
    staked_1: parseUint64Le(data, 20),
    staked_2: parseUint64Le(data, 28),
    staked_3: parseUint64Le(data, 36),
    staked_4: parseUint64Le(data, 44),
    staked_5: parseUint64Le(data, 52)
  };
}
export function calculateRewards(
  stakedData: StakedData,
  now: Date,
  rewardCalculator: RewardCalculator | null
  ){
  if (!rewardCalculator) return "0";

  let nowTimestamp = BigInt(Math.floor(now.getTime() / 1000));
  let { timestamp, withdrawn, rate, duration, decimals } = stakedData;
  let { minPeriod, rewardPeriod } = rewardCalculator;
  let reward = 0;
  let period = Number(nowTimestamp - timestamp);
  if (period < minPeriod) return "0";
// console.log("period"+period)
  
  // reward = period*Number(rate);
  reward = (Math.floor(period/86400))*Number(rate);
  reward -= Number(withdrawn);
  // console.log(Math.floor(reward));
  // return 1;
  return ( BigInt(Math.floor(reward)) / BigInt(10 ** decimals)).toString();
  // let reward = (rewardMultiplier * period) / rewardPeriod - withdrawn;
  // return (reward / BigInt(10 ** decimals)).toString();
}

export interface UnstakedData {
  mint: PublicKey;
  data: MetadataDataData;
  json: any;
  duration: any;
  timestamp:any;
  rank:any;
}
export interface StakedData extends UnstakedData {
  timestamp: bigint;
  withdrawn: bigint;
  rate: bigint;
  duration: bigint;
  decimals: number;
}

export async function getStakedDataByOwner(
  connection: Connection,
  owner: PublicKey
): Promise<StakedData[]> {
  let stakeDataAccounts = await connection.getProgramAccounts(PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner),
      createStakeTokenActiveFilter(),
    ],
  });

  return Promise.all(
    stakeDataAccounts.map(async ({ account: { data } }) => {
      let timestamp = parseUint64Le(data, 0);
      let mint = new PublicKey(data.slice(40, 72));
      let rank ='';
      let metadata = await Metadata.load(
        connection,
        await Metadata.getPDA(mint)
      );
      let json = await fetch(metadata.data.data.uri).then((e) => e.json());
      let withdrawn = parseUint64Le(data, 73);

      let candyMachineAddress = metadata.data.data.creators![0].address;
      // let rewardMultiplier = await getCandyMachineRate(
      //   connection,
      //   candyMachineAddress
      // );
      let rate = parseUint64Le(data, 81);
      // console.log("rewardMulitple ", rewardMultiplier);
      if (rate == null) {
        console.trace(
          `Warning: null rewardMultiplier for candy machine: ${candyMachineAddress}`
        );
        rate = BigInt(1);
      }
      let duration = parseUint64Le(data, 89);

      let decimals = await getRewardMintDecimals(connection);

      return {
        mint,
        data: metadata.data.data,
        json,
        timestamp,
        rank,
        withdrawn,
        rate,
        duration,
        decimals,
      };
    })
  );
}

export function createStakeTokenOwnerFilter(owner: PublicKey): MemcmpFilter {
  return {
    memcmp: {
      offset: 8,
      bytes: owner.toBase58(),
    },
  };
}

export function createStakeTokenActiveFilter(
  active: boolean = true
): MemcmpFilter {
  return {
    memcmp: {
      offset: 72,
      bytes: bs58.encode([active ? 1 : 0]),
    },
  };
}
export async function getAllTokens(
  connection: Connection,
  wallet: PublicKey
): Promise<any> {
  const tokenAccounts = await connection.getTokenAccountsByOwner(
    wallet,
    {
      programId: TOKEN_PROGRAM_ID,
    }
  );
  tokenAccounts.value.forEach(async (e) => {
    const accountInfo = AccountLayout.decode(e.account.data);

    //Tokens
    let addy =await getWhitelistDataAddress(new PublicKey(accountInfo.mint));
    let addyAccountInfo = await connection.getAccountInfo(addy);

    if(addyAccountInfo){

      console.log(`${new PublicKey(accountInfo.mint)}   ${parseUint64Le(accountInfo.amount)}`);
    }
    // console.log(JSON.stringify(accountInfo));
  })

  //NFTs
  const walletNfts = await Metadata.findDataByOwner(connection, wallet);
  await Promise.all(
    walletNfts.map(async ({ mint, data }) => {
      if (
        data.creators &&
        data.creators[0]?.verified
      ){
        //NFTs
        let addy =await getWhitelistDataAddress(new PublicKey(data.creators[0].address));
        let addyAccountInfo = await connection.getAccountInfo(addy);
        if(addyAccountInfo){

          console.log(`${new PublicKey(mint)}`);
        }
      }
    
    })
  );

  return;
}

export async function createStakeTokenTransactionHelper(
  connection: Connection,
  owner: PublicKey,
  time_index: any,
  token:PublicKey
): Promise<Transaction> {
    let metadata = await Metadata.load(connection, await Metadata.getPDA(token));
    let candyMachineAddress = metadata.data.data.creators![0].address;
    let candyMachine = new PublicKey(candyMachineAddress);
    
    let sourceTokenAccount = null;
    let { value: accounts } = await connection.getTokenLargestAccounts(token);
    for (let { address, amount } of accounts)
    if (amount === "1") sourceTokenAccount = address;
    if (!sourceTokenAccount)
    throw new Error(`Could not get current owner for ${token}`);
    let transaction = new Transaction();
    transaction.add(
      await createStakeTokenInstruction(
        owner,
        token,
        candyMachine,
        time_index,
        sourceTokenAccount
      )
      );
      
      let primarySourceTokenAccount = await getAssociatedTokenAddress(owner, token);
      if (!primarySourceTokenAccount.equals(sourceTokenAccount))
      transaction.add(
        Token.createCloseAccountInstruction(
          TOKEN_PROGRAM_ID,
          sourceTokenAccount,
          primarySourceTokenAccount,
          owner,
          []
        )
      );
  // console.log(transaction)
  return transaction;
}

export async function createStakeTokenTransaction(
  connection: Connection,
  owner: PublicKey,
  time_index: any,
  tokenArray: PublicKey[]
): Promise<Transaction[]> {
    
    let transactions:Transaction[] = [];
      for (let token in tokenArray){
      transactions.push(await createStakeTokenTransactionHelper(connection, owner, time_index,tokenArray[token]));
    }
    return transactions;
}
export async function createStakeTokenInstruction(
  owner: PublicKey,
  token: PublicKey,
  candyMachine: PublicKey,
  time_index: any,
  sourceTokenAccount?: PublicKey
): Promise<TransactionInstruction> {
  let address = await getaddress();
  let metadataAddress = await Metadata.getPDA(token);

  if (!sourceTokenAccount)
    sourceTokenAccount = await getAssociatedTokenAddress(owner, token);
  let destinationTokenAccount = await getAssociatedTokenAddress(
    address,
    token,
    true
  );

  let stakeDataAddress = await getStakeDataAddress(token);
  let whitelistDataAddress = await getWhitelistDataAddress(candyMachine);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("Stake", time_index),
    keys: [
      transactionKey(owner, true),
      transactionKey(token, false, false),
      transactionKey(metadataAddress, false, false),

      transactionKey(address, false),
      transactionKey(sourceTokenAccount, false),
      transactionKey(destinationTokenAccount, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(stakeDataAddress, false),
      transactionKey(whitelistDataAddress, false),
    ],
  });
}

export async function createUnstakeTokenTransactionHelper(
  connection: Connection,
  owner: PublicKey,
  token: PublicKey
): Promise<Transaction> {
  let metadata = await Metadata.load(connection, await Metadata.getPDA(token));
  let candyMachineAddress = metadata.data.data.creators![0].address;
  let candyMachine = new PublicKey(candyMachineAddress);

  let transaction = new Transaction();
  transaction.add(
    await createUnstakeTokenInstruction(owner, token, candyMachine)
  );
  return transaction;
}
export async function createUnstakeTokenTransaction(
  connection: Connection,
  owner: PublicKey,
  tokenArray: PublicKey[]
): Promise<Transaction[]> {
  let transactions:Transaction[] = [];
      for (let token in tokenArray){
        transactions.push(await createUnstakeTokenTransactionHelper(connection, owner, tokenArray[token]));
      }
  return transactions;
}
export async function createUnstakeTokenInstruction(
  owner: PublicKey,
  token: PublicKey,
  candyMachine: PublicKey
): Promise<TransactionInstruction> {
  let address = await getaddress();
  let metadataAddress = await Metadata.getPDA(token);
  console.log(address.toString())
  let sourceTokenAccount = await getAssociatedTokenAddress(
    address,
    token,
    true
  );
  let destinationTokenAccount = await getAssociatedTokenAddress(owner, token);

  let sourceRewardAccount = await getAssociatedTokenAddress(
    address,
    REWARD_MINT,
    true
  );
  let destinationRewardAccount = await getAssociatedTokenAddress(
    owner,
    REWARD_MINT
  );

  let penaltyRewardAccount = await getAssociatedTokenAddress(
    PENALTY,
    REWARD_MINT
  );

  let stakeDataAddress = await getStakeDataAddress(token);
  let whitelistDataAddress = await getWhitelistDataAddress(candyMachine);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("Unstake", null),
    keys: [
      transactionKey(owner, true),
      transactionKey(SystemProgram.programId, false, false),
      transactionKey(token, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(stakeDataAddress, false),
      transactionKey(address, false),
      transactionKey(PENALTY, false),

      transactionKey(destinationRewardAccount, false),
      transactionKey(sourceRewardAccount, false),
      transactionKey(penaltyRewardAccount, false),
      transactionKey(destinationTokenAccount, false),
      transactionKey(sourceTokenAccount, false),

      transactionKey(metadataAddress, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(REWARD_MINT, false, false),
    ],
  });
}

export async function createStakeUpgradeTokenTransactionHelper(
  connection: Connection,
  owner: PublicKey,
  token: PublicKey
): Promise<Transaction> {
  let metadata = await Metadata.load(connection, await Metadata.getPDA(token));
  let candyMachineAddress = metadata.data.data.creators![0].address;
  let candyMachine = new PublicKey(candyMachineAddress);

  let transaction = new Transaction();
  transaction.add(
    await createStakeUpgradeTokenInstruction(owner, token, candyMachine)
  );
  return transaction;
}
export async function createStakeUpgradeTokenTransaction(
  connection: Connection,
  owner: PublicKey,
  tokenArray: PublicKey[]
): Promise<Transaction[]> {
  let transactions:Transaction[] = [];
      for (let token in tokenArray){
        transactions.push(await createStakeUpgradeTokenTransactionHelper(connection, owner, tokenArray[token]));
      }
  return transactions;
}
export async function createStakeUpgradeTokenInstruction(
  owner: PublicKey,
  token: PublicKey,
  candyMachine: PublicKey
): Promise<TransactionInstruction> {
  let address = await getaddress();
  let metadataAddress = await Metadata.getPDA(token);

  let stakeDataAddress = await getStakeDataAddress(token);
  let whitelistDataAddress = await getWhitelistDataAddress(candyMachine);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("StakeUpgrade", null),
    keys: [
      transactionKey(owner, true),
      transactionKey(token, false, false),

      transactionKey(stakeDataAddress, false),
      transactionKey(address, false),

      transactionKey(metadataAddress, false, false),
      transactionKey(whitelistDataAddress, false),
    ],
  });
}

export async function createWithdrawRewardTransactionHelper(
  connection: Connection,
  owner: PublicKey,
  token: PublicKey
): Promise<Transaction> {
  let metadata = await Metadata.load(connection, await Metadata.getPDA(token));
  let candyMachineAddress = metadata.data.data.creators![0].address;
  let candyMachine = new PublicKey(candyMachineAddress);

  let transaction = new Transaction();
  transaction.add(
    await createWithdrawRewardInstruction(owner, token, candyMachine)
  );
  return transaction;
}
export async function createWithdrawRewardTransaction(
  connection: Connection,
  owner: PublicKey,
  tokenArray: PublicKey[]
): Promise<Transaction[]> {

  let transactions:Transaction[] = [];
  for(let token in tokenArray){
    transactions.push(
      await createWithdrawRewardTransactionHelper(connection, owner, tokenArray[token])
    );
  }
  return transactions;
}
export async function createWithdrawRewardInstruction(
  owner: PublicKey,
  token: PublicKey,
  candyMachine: PublicKey
): Promise<TransactionInstruction> {
  let address = await getaddress();
  let metadataAddress = await Metadata.getPDA(token);

  let sourceTokenAccount = await getAssociatedTokenAddress(
    address,
    token,
    true
  );
  let destinationTokenAccount = await getAssociatedTokenAddress(owner, token);

  let sourceRewardAccount = await getAssociatedTokenAddress(
    address,
    REWARD_MINT,
    true
  );
  let destinationRewardAccount = await getAssociatedTokenAddress(
    owner,
    REWARD_MINT
  );

  let stakeDataAddress = await getStakeDataAddress(token);
  let whitelistDataAddress = await getWhitelistDataAddress(candyMachine);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("StakeWithdraw", null),
    keys: [
      transactionKey(owner, true),
      transactionKey(SystemProgram.programId, false, false),
      transactionKey(token, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(stakeDataAddress, false),
      transactionKey(address, false, false),

      transactionKey(destinationRewardAccount, false),
      transactionKey(sourceRewardAccount, false),
      transactionKey(destinationTokenAccount, false),
      transactionKey(sourceTokenAccount, false),

      transactionKey(metadataAddress, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(REWARD_MINT, false, false),
    ],
  });
}


export async function createAdminUnstakeTokenTransactionHelper(
  connection: Connection,
  owner: PublicKey,
  token: PublicKey
): Promise<Transaction> {
  let metadata = await Metadata.load(connection, await Metadata.getPDA(token));
  let candyMachineAddress = metadata.data.data.creators![0].address;
  let candyMachine = new PublicKey(candyMachineAddress);
console.log(candyMachine)
  let transaction = new Transaction();
  transaction.add(
    await createAdminUnstakeTokenInstruction(owner, token, candyMachine)
  );
  return transaction;
}
export async function createAdminUnstakeTokenTransaction(
  connection: Connection,
  owner: PublicKey,
  tokenArray: PublicKey[]
): Promise<Transaction[]> {
  
  let transactions:Transaction[] = [];
      for (let token in tokenArray){
        console.log(tokenArray[token].toString(),owner.toString())
        transactions.push(await createAdminUnstakeTokenTransactionHelper(connection, owner, tokenArray[token]));
      }
  return transactions;
}
export async function createAdminUnstakeTokenInstruction(
  owner: PublicKey,
  token: PublicKey,
  candyMachine: PublicKey
): Promise<TransactionInstruction> {
  let address = await getaddress();
  let metadataAddress = await Metadata.getPDA(token);
 
  let sourceTokenAccount = await getAssociatedTokenAddress(
    address,
    token,
    true
  );
  let destinationTokenAccount = await getAssociatedTokenAddress(owner, token);

  let sourceRewardAccount = await getAssociatedTokenAddress(
    address,
    REWARD_MINT,
    true
  );
  let destinationRewardAccount = await getAssociatedTokenAddress(
    owner,
    REWARD_MINT
  );

  let stakeDataAddress = await getStakeDataAddress(token);
  let whitelistDataAddress = await getWhitelistDataAddress(candyMachine);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("AdminUnstake", null),
    keys: [
      transactionKey(ADMIN, true),
      transactionKey(owner, false, true),
      transactionKey(SystemProgram.programId, false, false),
      transactionKey(token, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(stakeDataAddress, false),
      transactionKey(address, false),

      transactionKey(destinationRewardAccount, false),
      transactionKey(sourceRewardAccount, false),
      transactionKey(destinationTokenAccount, false),
      transactionKey(sourceTokenAccount, false),

      transactionKey(metadataAddress, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(REWARD_MINT, false, false),
    ],
  });
}