import React, { FC, useCallback, useEffect, useReducer, useState } from "react";

export const Loader: FC = () => {


    return (<>
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
            <div className="loading-text"> Processing, N/W Congestion might affect the page loading ...</div>
        </div>
    </>);
};